import jwtDecode from 'jwt-decode';
import { postData } from './index';
import { DispatchType } from '../reducers';
import {
  AUTH_GET_PROFILE,
  AUTH_LOADING,
  AUTH_ERROR,
} from './actions';
import {
  ILogin,
  IForgotPassword,
  IResetPassword,
} from '../types/auth.types';

export const postLoginAction = async (dispatch: DispatchType, data: ILogin): Promise<any> => {
  const url = '/auth/login';
  let token = null;
  dispatch({
    type: AUTH_LOADING,
  });
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    token = response.data.token;
  });
  if (token) {
    localStorage.setItem('token', token);
    dispatch({
      type: AUTH_GET_PROFILE,
      payload: jwtDecode(token),
    });
  }
  return promise;
};

export const forgotPassword = async (
  dispatch: DispatchType,
  data: IForgotPassword,
): Promise<any> => {
  const url = '/auth/forgot';
  const promise = await postData(AUTH_ERROR, url, dispatch, data, false).then((response: any) => {
    console.log(response);
  });
  return promise;
};

export const resetPassword = async (dispatch: DispatchType, data: IResetPassword): Promise<any> => {
  const url = `/auth/reset/${data.token}`;
  const promise = await postData(
    AUTH_ERROR,
    url,
    dispatch,
    { password: data.password },
    false,
  ).then((response: any) => {
    console.log(response);
  });
  return promise;
};
