import { IOption } from '../lib/HooksFormFields/StyledSelect/style';

const financesLastYears : IOption[] = [];
let endYear = 2025;

while (endYear > 2001) {
  financesLastYears.push({ value: endYear, label: endYear.toString() });
  endYear -= 1;
}

const years : IOption[] = [
  {
    label: 'Last 5 years',
    value: '5',
  },
  {
    label: 'All years',
    value: 'all',
  },
];

const types : IOption[] = [
  {
    value: 'Q1',
    label: 'Q1',
  },
  {
    value: 'H1',
    label: 'H1',
  },
  {
    value: '9M',
    label: '9M',
  },
  {
    value: 'FY',
    label: 'FY',
  },
];

export type ExportList = {
  years: IOption[],
  companies: IOption[],
  indicators: IOption[],
  template: IOption[] | IOption,
};

export type ILists = {
  types: IOption[],
  years: IOption[],
  financesLastYears: IOption[],
  export?: ExportList,
};

const lists : ILists = {
  types,
  years,
  financesLastYears,
};

export default lists;
