import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import { useAppSelector } from '../../hooks/useRedux';
import styles from './Page404.module.scss';

export default function Page404() {
  const navigate = useNavigate();
  const { userAuth } = useAppSelector((state: any) => state.auth);
  function goBack() {
    if (!userAuth?.role) {
      navigate('/login');
    } else {
      navigate(-1);
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src={logo}
          alt="logo"
        />
      </div>
      <h4>
        Page not found
      </h4>
      <button
        onClick={() => goBack()}
        className={`${styles.btn} ${styles.primary}`}>
        Previous page
      </button>
    </div>
  );
}
