import {
  deleteData, getData, postData, putData,
} from './index';
import { DispatchType } from '../reducers';
import {
  GET_USERS, POST_USER, PUT_USER, SET_TOAST, USER_ERROR, USER_LOADING, DELETE_USER,
} from './actions';
import { IUser } from '../types/auth.types';
import { IUserForm } from '../types/users.types';

export const getUsersAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/user';
  let users : IUser[] | null = null;
  dispatch({
    type: USER_LOADING,
  });

  const promise = await getData(USER_ERROR, url, dispatch, true).then((response: any) => {
    users = response.data.users;
  });

  dispatch({
    type: GET_USERS,
    payload: users,
  });
  return promise;
};

export const postUserAction = async (dispatch: DispatchType, data: IUserForm): Promise<any> => {
  const url = '/auth/register';
  let user : IUser | null = null;
  let message : string | null = null;
  dispatch({
    type: USER_LOADING,
  });
  const promise = await postData(USER_ERROR, url, dispatch, data, true).then((response: any) => {
    user = response.data.user;
    message = response.data.message;
  });
  if (user) {
    dispatch({
      type: POST_USER,
      payload: user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message,
      },
    });
  }
  return promise;
};

export const putUserAction = async (
  dispatch: DispatchType,
  id: string,
  data: IUser,
): Promise<any> => {
  const url = `/user/${id}`;
  let user : IUser | null = null;
  dispatch({
    type: USER_LOADING,
  });
  const promise = await putData(
    PUT_USER,
    USER_ERROR,
    url,
    dispatch,
    data,
    true,
  ).then((response: any) => {
    user = response.user;
  });
  if (user) {
    dispatch({
      type: PUT_USER,
      payload: user,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message: 'User updated',
      },
    });
  }
  return promise;
};

export const deleteUserAction = async (dispatch: DispatchType, id: string): Promise<any> => {
  const url = `/user/${id}`;
  let message : string | null = null;
  dispatch({
    type: USER_LOADING,
  });

  const promise = await deleteData(USER_ERROR, url, dispatch, true).then((response: any) => {
    message = response.message;
  });

  if (message) {
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    dispatch({
      type: SET_TOAST,
      payload: {
        type: 'success',
        message,
      },
    });
  }
  return promise;
};
