import React from 'react';
import sortIcon from './sort.svg';

export type ISortItem = {
  label: string,
  value: string,
  className?: string,
  isAsc?: Boolean | undefined,
};

const SortItem = ({
  item, isAsc, setActiveSort, styles,
}:{
  item: ISortItem,
  setActiveSort: (item: ISortItem) => void,
  isAsc: Boolean,
  styles: any
}) => (
    <div className={`${styles.col} ${styles['sort-item']} ${item.className ? styles[item.className] : ''}`} onClick={() => setActiveSort({ ...item, isAsc: !isAsc })}>
      <span>{item.label}</span>
      <img src={sortIcon} className={isAsc ? styles.active : ''} alt="filter"/>
    </div>
);

export default SortItem;
