/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { AnyObject } from '../types';
import { DispatchType } from '../reducers';
import { AUTH_ERROR, SET_UPLOAD_PROGRESS } from './actions';

export const API_URL = process.env.REACT_APP_API_URL;

export function errorHandler(dispatch: DispatchType, error: any, type: string): void {
  console.log('Error type: ', type);
  console.log(error);

  if (type !== AUTH_ERROR && error.response !== undefined && error.response.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    if (localStorage.getItem('id')) {
      localStorage.removeItem('id');
    }
    window.location.href = '/';
  }
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export function postData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  data: any,
  isAuthReq: boolean,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.post(requestUrl, data, config)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function getData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  isAuthReq: boolean,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.get(requestUrl, config)
      .then((response: any) => {
        resolve(response);
      })
      .catch((error: any) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function putData(
  action: string,
  errorType: string,
  url: string,
  dispatch: DispatchType,
  data: AnyObject,
  isAuthReq = true,
) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }
    axios.put(requestUrl, data, config)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject(action);
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function deleteData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  isAuthReq: boolean,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.delete(requestUrl, config)
      .then((response: any) => {
        resolve(response.data);
      })
      .catch((error: any) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}

export function postFiles(
  action: string,
  errorType: string,
  url: string,
  dispatch: DispatchType,
  data: FormData,
  info: any,
  isAuthReq : boolean = false,
) {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config : AnyObject = {
      onUploadProgress(progressEvent: ProgressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        const progress = {
          progress: percentCompleted,
          fileState: 'progress',
          _id: info._id,
          name: info.name,
        };

        dispatch({
          type: SET_UPLOAD_PROGRESS,
          payload: progress,
        });
      },
    };

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.post(requestUrl, data, config)
      .then((response) => {
        const result200 = {
          progress: 100,
          fileState: 'fileUpdated',
          _id: info._id,
          name: info.name,
          response: response.data,
        };
        dispatch({
          type: action,
          payload: result200,
        });
        resolve(result200);
      })
      .catch((error) => {
        let message = '';

        if (!error.status) {
          message = 'network error';
        }

        if (error.response && error.response.data.error) {
          message = error.response.data.error;
        }

        const result500 = {
          progress: 100,
          fileState: 'error',
          _id: info._id,
          name: info.name,
          message,
        };
        dispatch({
          type: action,
          payload: result500,
        });
        reject();
      });
  });
}
