import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import {
  Button, ErrorField, InputFile, StyledSelect,
} from '../../lib/HooksFormFields';
import img from './finance.svg';
import styles from './FinancialFileModale.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import requiredRule from '../../constants';
import { ICompanyFileForm } from '../../types/companies.types';
import { uploadCompanyFileAction } from '../../actions/companies';
import Loader from '../Loader';
import { UPLOAD_COMPANY_MODALE } from '../../actions/actions';

export default function FinancialFileModale({ close }: { close: () => void }) {
  const dispatch = useAppDispatch();
  const { modales, lists, error } = useAppSelector((state: any) => state.companies);
  const { companyModale } = modales;

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<ICompanyFileForm>({ mode: 'all' });

  async function onSubmit(form : ICompanyFileForm) {
    const formData = new FormData();
    const options = {
      _id: companyModale._id,
      financeLastPeriod: form.financeLastPeriod.value,
      financeLastYear: form.financeLastYear.value,
      selectedYears: form.selectedYears.value,
    };
    formData.append('file', form.file[0]);
    formData.append('options', JSON.stringify(options));
    await uploadCompanyFileAction(dispatch, formData, companyModale);
    // close();
  }

  console.log(companyModale, error);

  return (
    <div className={styles.container}>
      {companyModale?.fileState !== 'loading'
        && <button
          type="button"
          className={styles.close}
          onClick={() => close()}
        >
          <IoMdClose />
        </button>
      }
      <div className={styles.img}>
        <img src={img} alt="add-img" />
      </div>

      {companyModale?.fileState === 'upload'
        && <div
          className={styles.form}
        >
          <h3>Upload your file for {companyModale?.name}</h3>
          <div className={styles.field}>
            <InputFile
              control={control}
              name='file'
              id='input-file'
              btn={watch('file')?.length > 0 ? watch('file')[0].name : 'Choose a file'}
              rules={requiredRule}
            />
            <ErrorField message={errors?.file?.message}/>
          </div>
          <div className={styles.field}>
            <StyledSelect
              control={control}
              options={lists.types}
              rules={requiredRule}
              placeholder="Choose the finance last period"
              name='financeLastPeriod'
              label='Finance last period'
            />
            <ErrorField message={errors?.financeLastPeriod?.message}/>
          </div>
          <div className={styles.field}>
            <StyledSelect
              control={control}
              options={lists.financesLastYears}
              rules={requiredRule}
              placeholder="Choose the finance last year"
              name='financeLastYear'
              label='Choose the finance last year'
            />
            <ErrorField message={errors?.financeLastYear?.message}/>
          </div>
          <div className={styles.field}>
            <StyledSelect
              control={control}
              options={lists.years}
              rules={requiredRule}
              placeholder="Choose the finance period"
              name='selectedYears'
              label='Choose the finance period'
            />
            <ErrorField message={errors?.selectedYears?.message}/>
          </div>
          <Button
            type="button"
            handleClick={handleSubmit(onSubmit)}
            className={'primary'}
            isLoading={false}
          >
            <>
              <HiCheck /> Validate
            </>
          </Button>
        </div>
      }
      {companyModale?.fileState === 'loading' && !error?.data
        && <div
          className={styles.form}
          >
          <h3>
            <span className={styles.name}>{companyModale?.name}</span>
            {' '}
            file is in the process of updating</h3>
          <p>it may take several minutes</p>
          <div className={styles.loader}>
            <Loader />
          </div>
        </div>
      }
      {companyModale?.fileState === 'error'
        && <div
          className={styles.form}
          >
          <h3>An error append during the process</h3>
            <div className={styles.error}>
            <div className={styles.icon}>
              <MdOutlineErrorOutline />
            </div>
            <p>{companyModale?.message || 'the process of updating failed'}</p>

            <button
              type="button"
              className={styles.primary}
              onClick={() => dispatch({
                type: UPLOAD_COMPANY_MODALE,
                payload: { ...companyModale, fileState: 'upload', message: null },
              })}
            >
              Change file
            </button>
          </div>
        </div>
      }
      {companyModale?.fileState === 'success'
        && <div
          className={styles.form}
          >
          <h3>Your <span className={styles.name}>
            {companyModale?.name}</span>
            {' '}
            file is is updated
          </h3>
          <div className={styles.success}>
            <div className={styles.icon}>
              <HiCheck />
            </div>
            <p>{companyModale?.name} replaced</p>
          </div>
        </div>
      }
    </div>
  );
}
