import { StylesConfig } from 'react-select';

export type IOption = {
  value?: string | number,
  label?: string
};
const primary = '#004E9C';

export const style1: StylesConfig<IOption, boolean> = {
  container: (provided) => ({ ...provided }),
  control: (provided) => ({
    ...provided,
    minHeight: 38,
    borderRadius: 0,
    backgroundColor: '#FAFAFB',
    boxShadow: '0 0 0 1px transparent',
    borderColor: 'transparent',
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({
    ...provided,
    maxHeight: 180,
    overflow: 'auto',
  }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValue: (provided) => ({
    ...provided,
    fontSize: '14px',
    backgroundColor: primary,
    color: 'white',
    padding: 5,
    borderRadius: 6,
  }),
  multiValueLabel: (provided) => ({ ...provided, color: 'white', fontSize: '14px' }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? primary : '',
    color: !state.isSelected ? primary : 'white',
    '&:hover': {
      backgroundColor: primary,
      color: 'white',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
};

export const style2: StylesConfig<IOption, boolean> = {
  container: (provided) => ({ ...provided }),
  control: (provided, state) => ({
    ...provided,
    minHeight: 50,
    borderRadius: 9,
    backgroundColor: '#FFFFFF',
    boxShadow: '0 0 0 1px transparent',
    borderColor: 'transparent',
    fontSize: '16px',
    fontFamily: 'Montserrat-SemiBold',
    '&:hover': {
      borderColor: state.isFocused ? primary : '',
    },
  }),
  placeholder: (provided) => ({ ...provided, fontSize: '14px' }),
  valueContainer: (provided) => ({ ...provided }),
  singleValue: (provided) => ({ ...provided, fontSize: '14px' }),
  multiValue: (provided) => ({
    ...provided, fontSize: '14px', backgroundColor: primary, color: 'white', padding: 5, borderRadius: 6,
  }),
  multiValueLabel: (provided) => ({ ...provided, color: 'white', fontSize: '14px' }),
  multiValueRemove: (provided) => ({ ...provided }),
  input: (provided) => ({
    ...provided,
    '&:focus': {
      boxShadow: '',
    },
  }),
  dropdownIndicator: (provided) => ({ ...provided }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '12px',
    backgroundColor: state.isSelected ? primary : '',
    color: !state.isSelected ? primary : '',
    '&:hover': {
      backgroundColor: primary,
      color: 'white',
    },
  }),
  clearIndicator: (provided) => ({ ...provided }),
  indicatorSeparator: () => ({ display: 'none' }),
};
