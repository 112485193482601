import React, { useCallback, useEffect } from 'react';
import { getUsersAction } from '../../../../actions/user';
import UserList from '../../../../components/UserList/UserList';
import { useAppDispatch } from '../../../../hooks/useRedux';
import styles from './Users.module.scss';

export default function Users() {
  const dispatch = useAppDispatch();
  const getUsers = useCallback(() => {
    getUsersAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getUsers();
  }, []);
  return (
    <div className={styles.container}>
      <UserList />
    </div>
  );
}
