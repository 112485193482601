import React from 'react';
import { SET_CONFIRM } from '../../actions/actions';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import styles from './CustomConfirm.module.scss';

export interface IConfirmAction {
  message: string,
  action: () => void,
}

export default function CustomConfirm() {
  const { confirmAction } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  function cancel() {
    dispatch({
      type: SET_CONFIRM,
      payload: null,
    });
  }

  async function handleAction() {
    await confirmAction?.action();
    cancel();
  }
  return (
    <div className={`${styles.container} ${confirmAction?.action ? styles.active : ''}`}>
      <p>{confirmAction?.message}</p>
      <div className={styles.actions}>
        <button
          type="button"
          className={`${styles.btn} ${styles.primary}`}
          onClick={() => handleAction()}
        >
          Confirm
        </button>
        <button
          type="button"
          className={`${styles.btn} ${styles.primary}`}
          onClick={() => cancel()}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
