import React from 'react';
import {
  Outlet,
} from 'react-router-dom';
import Nav from '../../components/Nav/Nav';
import styles from './Finance.module.scss';

function Finance() {
  return (
    <div className={styles.container}>
      <Nav />
      <main>
        <Outlet />
      </main>
    </div>
  );
}

export default Finance;
