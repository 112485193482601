import React, { useEffect, useState } from 'react';
import { BiDownArrowAlt } from 'react-icons/bi';
import adminIcon from './admin.svg';
import memberIcon from './member.svg';
import ownerIcon from './owner.svg';
import bucketIcon from './bucket.svg';

import styles from './SelectRole.module.scss';

const roles = ['Admin', 'Member', 'Owner', 'Bakasable'];

const Role = ({ role }: { role: string }) => (
    <>
      {role === 'Admin'
        && <div className={styles.role}>
          <img src={adminIcon} alt="admin-icon"/>
          <p>Administrator</p>
        </div>
      }
      {role === 'Owner'
        && <div className={styles.role}>
          <img src={ownerIcon} alt="owner-icon"/>
          <p>Owner</p>
        </div>
      }
      {role === 'Member'
        && <div className={styles.role}>
          <img src={memberIcon} alt="member-icon"/>
          <p>Member</p>
        </div>
      }
      {role === 'Bakasable'
        && <div className={styles.role}>
          <img src={bucketIcon} alt="bucket-icon"/>
          <p>Bakasable</p>
        </div>
      }
    </>
);

export default function SelectRole({
  role,
  index,
  handleChange,
}: {
  role: string,
  index: number,
  handleChange: (string: string) => void,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function selectRole(value : string) {
    handleChange(value);
    setIsOpen(false);
  }

  useEffect(() => {
    setIsOpen(false);
  }, [index, role]);
  return (
    <div className={styles.container}>
      <div className={styles.selected}>
        <Role role={role} />
        <button
          className={`${styles.toggle} ${isOpen ? styles.open : ''}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <BiDownArrowAlt />
        </button>
      </div>
      <div
        className={`${styles.options} ${isOpen ? styles.open : ''}`}
        onMouseLeave={() => setIsOpen(false)}
      >
        {roles.map((opt : string) => (
          <button
            key={opt}
            type='button'
            onClick={() => selectRole(opt)}
          >
            <Role role={opt} />
          </button>
        ))}
      </div>
    </div>
  );
}
