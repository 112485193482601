import React from 'react';
import styles from './button.module.scss';

interface IButton {
  children: JSX.Element | string,
  type: string,
  className?: string
  isLoading: boolean,
  handleClick: () => void,
}

function Button({
  children,
  type,
  isLoading,
  className = '',
  handleClick,
}: IButton) {
  return (
    <button type="button" onClick={handleClick} className={`${styles.button} ${className && styles[className]} ${styles[type]} ${isLoading ? styles.loading : ''}`}>
      {children}
    </button>
  );
}

export default Button;
