import React from 'react';
import { RiLogoutCircleRLine } from 'react-icons/ri';
import { NavLink, useNavigate } from 'react-router-dom';
import { AUTH_LOGOUT } from '../../actions/actions';
import logo from '../../assets/images/logo.png';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import styles from './Nav.module.scss';

export default function Nav() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { userAuth } = useAppSelector((state: any) => state.auth);

  function logout() {
    navigate('/login');
    dispatch({
      type: AUTH_LOGOUT,
    });
  }

  return (
    <header className={styles.container}>
      <div className={styles.logo}>
        <img
          src={logo}
          alt="logo"
        />
      </div>
      <div className={styles.links}>
        <NavLink to="/finance/manufacturers" className={({ isActive }) => (isActive ? styles.active : '')}>
          <p>Manufacturers</p>
        </NavLink>
        <NavLink to="/finance/users" className={({ isActive }) => (isActive ? styles.active : '')}>
          <p>Users</p>
        </NavLink>
      </div>
      <div className={styles.auth}>
        {userAuth?.firstName
          && <div className={styles.user}>
            {userAuth?.firstName[0]}{userAuth?.lastName[0]}
          </div>
        }
        <button
          onClick={() => logout()}
          className={styles.logout}>
          <RiLogoutCircleRLine />
        </button>
      </div>
    </header>
  );
}
