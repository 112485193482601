import {
  configureStore,
} from '@reduxjs/toolkit';

import auth from './auth.reducer';
import users from './users.reducer';
import companies from './companies.reducer';

export const reducers = configureStore({
  reducer: {
    auth,
    users,
    companies,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      // ignoredActions: ['your/action/type'],
      // // Ignore these field paths in all actions
      ignoredActionPaths: ['payload.action'],
      // // Ignore these paths in the state
      ignoredPaths: ['auth.confirmAction.action'],
    },
  }),
});

// Infer the `ApplicationState` and `DispatchType` types from the store itself
export type ApplicationState = ReturnType<typeof reducers.getState>;
export type DispatchType = typeof reducers.dispatch;
