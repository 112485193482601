import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './input-file.module.scss';

interface IInputFile {
  name: string,
  id: string,
  btn?: string | null,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rules: any,
  label?: string | null,
  inline?: boolean,
  widthLabel?: string | null,
  placeholder?: string,
  defaultValue?: string,
  className?: string,
}

function InputFile({
  name,
  control,
  rules,
  id,
  btn = 'Choose a file',
  defaultValue = '',
  widthLabel = null,
  inline = false,
  label = null,
  placeholder,
  className,
}: IInputFile) {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: {
          // name,
          ref,
          onChange,
          onBlur,
        },
      }) => (
        <div className={inline ? `${className ? styles[className] : ''} ${styles['container-input']} ${styles.inline}` : `${className ? styles[className] : ''} ${styles['container-input']}`}>
          {label && <p style={widthLabel ? { minWidth: widthLabel } : {}}>{label}</p>}
          <label htmlFor={id} className={styles.input}>{btn}</label>
          <input
            ref={ref}
            id={id}
            type="file"
            onBlur={onBlur}
            onChange={(e) => onChange(e.target.files)}
            placeholder={placeholder}
          />
        </div>
      )}
    />
  );
}

export default InputFile;
