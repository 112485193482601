import { utils, writeFileXLSX } from 'xlsx';
import {
  getData, postData, postFiles,
} from './index';
import { DispatchType } from '../reducers';
import {
  GET_COMPANIES,
  COMPANY_ERROR,
  COMPANY_LOADING,
  EXPORT_COMPANY,
  SET_TOAST,
  UPLOAD_COMPANY_MODALE,
  UPLOAD_FILE_MODALE,
  GET_EXPORT_LISTS,
  EXPORT_MODALE,
} from './actions';
import {
  ExportFiltered,
  ICompany, IExportModale, IExportPayload, IFileModale,
} from '../types/companies.types';
import { ExportList } from '../constants/lists';

export const getExportListsAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/export/list';
  let list : ExportList[] | null = null;
  dispatch({
    type: COMPANY_LOADING,
  });

  const promise = await getData(COMPANY_ERROR, url, dispatch, true).then((response: any) => {
    list = response.data.list;
  });

  dispatch({
    type: GET_EXPORT_LISTS,
    payload: list,
  });
  return promise;
};

export const getCompaniesAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/company';
  let companies : ICompany[] | null = null;
  dispatch({
    type: COMPANY_LOADING,
  });

  const promise = await getData(COMPANY_ERROR, url, dispatch, true).then((response: any) => {
    companies = response.data.companies;
  });

  dispatch({
    type: GET_COMPANIES,
    payload: companies,
  });
  return promise;
};

export const exportCompanyAction = async (
  dispatch: DispatchType,
  data: IExportPayload,
): Promise<any> => {
  const url = '/export';

  dispatch({
    type: EXPORT_COMPANY,
    payload: { _id: data.company },
  });
  const promise = await postData(COMPANY_ERROR, url, dispatch, data, true);

  if (promise?.data) {
    const wb = utils.book_new();
    promise.data.dataToExport.forEach((sheet : any) => {
      const ws = utils.json_to_sheet(sheet.sheetData);
      utils.book_append_sheet(wb, ws, sheet.sheetName);
    });
    writeFileXLSX(wb, `${promise.data.fileName}.xlsx`);
  }
  dispatch({
    type: EXPORT_COMPANY,
    payload: { _id: data.company },
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'file exported',
    },
  });
  return promise;
};

export const exportCompaniesFilteredAction = async (
  dispatch: DispatchType,
  data: ExportFiltered,
  info: IExportModale,
): Promise<any> => {
  const url = '/export/template';

  dispatch({
    type: EXPORT_MODALE,
    payload: { ...info, fileState: 'loading' },
  });
  const promise = await postData(COMPANY_ERROR, url, dispatch, data, true);

  if (promise?.data) {
    try {
      const wb = utils.book_new();
      promise.data.dataToExport.forEach((sheet : any) => {
        const ws = utils.json_to_sheet(sheet.sheetData);
        const sheetName = sheet.sheetName.replace('/', ' ');
        utils.book_append_sheet(wb, ws, sheetName);
      });
      writeFileXLSX(wb, `${promise.data.fileName}.xlsx`);
    } catch (error) {
      console.error(error);
      // Expected output: ReferenceError: nonExistentFunction is not defined
      // (Note: the exact output may be browser-dependent)
    }
  }
  dispatch({
    type: EXPORT_MODALE,
    payload: { ...info, fileState: 'success' },
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'file exported',
    },
  });
  return promise;
};

export const uploadFormulaAction = async (
  dispatch: DispatchType,
  formData: FormData,
  info: IFileModale,
): Promise<any> => {
  const url = '/finance/uploadformula';

  dispatch({
    type: UPLOAD_FILE_MODALE,
    payload: { ...info, fileState: 'loading' },
  });
  const promise = await postData(COMPANY_ERROR, url, dispatch, formData, true);

  dispatch({
    type: UPLOAD_FILE_MODALE,
    payload: { ...info, fileState: 'success' },
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'formula file imported',
    },
  });
  return promise;
};

export const uploadExchangeAction = async (
  dispatch: DispatchType,
  formData: FormData,
  info: IFileModale,
): Promise<any> => {
  const url = '/finance/exchange';

  dispatch({
    type: UPLOAD_FILE_MODALE,
    payload: { ...info, fileState: 'loading' },
  });
  const promise = await postData(COMPANY_ERROR, url, dispatch, formData, true);

  dispatch({
    type: UPLOAD_FILE_MODALE,
    payload: { ...info, fileState: 'success' },
  });
  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'formula file imported',
    },
  });
  return promise;
};

export const uploadCompanyFileAction = async (
  dispatch: DispatchType,
  data: FormData,
  info: any,
): Promise<any> => {
  const url = '/finance/uploadfile';

  dispatch({
    type: UPLOAD_COMPANY_MODALE,
    payload: { ...info, fileState: 'loading' },
  });

  const promise = await postFiles(
    UPLOAD_COMPANY_MODALE,
    COMPANY_ERROR,
    url,
    dispatch,
    data,
    info,
    true,
  );

  if (promise) {
    console.log(promise);
  }
  dispatch({
    type: UPLOAD_COMPANY_MODALE,
    payload: { ...info, fileState: 'success' },
  });

  dispatch({
    type: SET_TOAST,
    payload: {
      type: 'success',
      message: 'file uploaded',
    },
  });
  return promise;
};
