import React, { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { EXPORT_MODALE, UPLOAD_COMPANY_MODALE, UPLOAD_FILE_MODALE } from '../../actions/actions';

import {
  ICompany, ICompanyModale, IExportModale, IFileModale,
} from '../../types/companies.types';

import Search from '../Search/Search';
import SortItem, { ISortItem } from '../SortItem/SortItem';
import Loader from '../Loader';
import { Button } from '../../lib/HooksFormFields';
import { exportCompanyAction } from '../../actions/companies';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import FinancialFileModale from '../FinancialFileModale/FinancialFileModale';
import FileModale from '../FileModale /FileModale';

import uploadImg from '../../assets/images/icons/upload.svg';

import styles from './CompaniesList.module.scss';
import ExportModale from '../ExportModale/ExportModale';

const CompanyRow = (
  {
    company,
    handleFinanceModale,
  }
  :
  {
    company: ICompany,
    handleFinanceModale: () => void,
  },
) => {
  const dispatch = useAppDispatch();
  const options : Intl.DateTimeFormatOptions = {
    weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit',
  };
  const updatedAt = new Intl.DateTimeFormat('en-US', options).format(new Date(company.updatedAt));
  return (
    <div className={`${styles.row} ${company?.isLoading ? styles.isLoading : ''}`}>
      <div className={styles.col}>
        <p>{company?.name}</p>
      </div>
      <div className={styles.col}>
        {<p>{updatedAt}</p>}
      </div>
      <div className={styles.col}>
        <div className={styles.upload}>
          <button
            type="button"
            onClick={() => handleFinanceModale()}
          >
            Submit Son file
          </button>
        </div>
        <div className={styles.export}>
          <Button
            type="button"
            className='primary'
            isLoading={!!company?.isLoading}
            handleClick={() => exportCompanyAction(dispatch, { company: company._id, type: 'fiance' })}
          >
            Output file
          </Button>
        </div>
      </div>
    </div>
  );
};

export default function CompaniesList() {
  const dispatch = useAppDispatch();
  const { companies, modales, lists } = useAppSelector((state: any) => state.companies);
  const { companyModale, fileModale, exportModale } = modales;
  const financeModalRef = useRef<IModalHandle>(null);
  const fileModalRef = useRef<IModalHandle>(null);
  const exportModalRef = useRef<IModalHandle>(null);
  const sortItems = [
    { value: 'name', label: 'Name' },
    { value: 'date', label: 'Last update' },
  ];
  const [activeSort, setActiveSort] = useState<ISortItem>({ ...sortItems[0], isAsc: false });
  const [filteredList, setFilteredList] = useState<ICompany[]>([]);
  const [search, setSearch] = useState('');

  function handleSort(companysList: ICompany[]) {
    let result = companysList
      .filter((u : ICompany) => {
        if (!search) {
          return u;
        }
        if (
          u.name
          && u.name.toLowerCase().includes(search.toLowerCase())
        ) {
          return u;
        }
        return null;
      });
    result = result.sort((a, b) => {
      const aName = a.name;
      const bName = b.name;
      const aUpdated = new Date(a.updatedAt).getTime();
      const bUpdated = new Date(b.updatedAt).getDate();
      switch (activeSort?.value) {
        case 'name':
          return aName.localeCompare(bName);
        case 'date':
          return bUpdated > aUpdated ? 1 : -1;
        default:
          return aName.localeCompare(bName);
      }
    });
    setFilteredList(!activeSort?.isAsc ? result : result.reverse());
  }

  function handleFinanceModale(value: ICompanyModale | null = null) {
    dispatch({
      type: UPLOAD_COMPANY_MODALE,
      payload: value,
    });
  }

  function handleFileModale(value: IFileModale | null = null) {
    dispatch({
      type: UPLOAD_FILE_MODALE,
      payload: value,
    });
  }

  function handleExportModale(value: IExportModale | null = null) {
    dispatch({
      type: EXPORT_MODALE,
      payload: value,
    });
  }

  useEffect(() => {
    if (companies) {
      handleSort(companies);
    }
  }, [companies, search, activeSort]);

  useEffect(() => {
    if (financeModalRef?.current) {
      if (companyModale) {
        financeModalRef.current.open();
      } else {
        financeModalRef.current.close();
      }
    }
  }, [companyModale, financeModalRef?.current]);

  useEffect(() => {
    if (exportModalRef?.current) {
      if (exportModale) {
        exportModalRef.current.open();
      } else {
        exportModalRef.current.close();
      }
    }
  }, [exportModale, exportModalRef?.current]);

  useEffect(() => {
    if (fileModalRef?.current) {
      if (fileModale) {
        fileModalRef.current.open();
      } else {
        fileModalRef.current.close();
      }
    }
  }, [fileModale, fileModalRef?.current]);
  console.log(lists);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.search}>
          <Search
            search={search}
            setSearch={setSearch}
          />
        </div>
        <div className={styles.files}>
          <button
            className={styles.upload}
            onClick={() => handleFileModale({
              name: 'Formula',
              fileState: 'upload',
            })}
          >
            <img src={uploadImg} alt=""/>Formula
          </button>
          <button
            className={styles.upload}
            onClick={() => handleFileModale({
              name: 'Exchange rates',
              fileState: 'upload',
            })}
          >
            <img src={uploadImg} alt=""/>Exchange rates
          </button>
          {lists?.export
          && <Button
            type="button"
            className='primary'
            isLoading={false}
            handleClick={() => handleExportModale({
              name: 'Export',
              fileState: 'upload',
            })}
          >
            Export
          </Button>
        }
        </div>
      </div>
      <div className={styles.row}>
        {sortItems.map((item) => <SortItem
          key={item.value}
          item={item}
          isAsc={!!(item.label === activeSort.label && activeSort.isAsc) }
          styles={styles}
          setActiveSort={setActiveSort}
        />)}
      </div>
      {companies
        ? <div className={styles.list}>
        {filteredList.map((c) => <CompanyRow
          key={c._id}
          company={c}
          handleFinanceModale={() => handleFinanceModale({
            progress: 0,
            fileState: 'upload',
            _id: c._id,
            name: c.name,
            response: null,
          })}
        />)}
      </div>
        : <div className={styles.loader}>
          <Loader/>
      </div>
      }
      <ModalStandalone ref={financeModalRef}>
        <FinancialFileModale
          close={() => handleFinanceModale()}
        />
      </ModalStandalone>
      <ModalStandalone ref={fileModalRef}>
        <FileModale
          close={() => handleFileModale()}
        />
      </ModalStandalone>
      <ModalStandalone ref={exportModalRef}>
        <ExportModale
          close={() => handleExportModale()}
        />
      </ModalStandalone>
    </div>
  );
}
