import React, { useEffect } from 'react';
import {
  useNavigate,
} from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useAppSelector, useAppDispatch } from '../../../hooks/useRedux';
import { postLoginAction } from '../../../actions/auth';
import { ILogin } from '../../../types/auth.types';

import {
  InputEmail,
  InputPassword,
  ErrorField,
} from '../../../lib/HooksFormFields';

import logo from '../../../assets/images/logo.png';

import styles from './Login.module.scss';

export default function Login() {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILogin>();

  const { error, userAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<ILogin> = (data) => {
    postLoginAction(dispatch, data);
  };

  useEffect(() => {
    if (userAuth?.role) {
      navigate('/finance/manufacturers');
    }
  }, [userAuth]);

  return (
    <div className={styles.container}>
      <form
        autoComplete="off"
        className={styles.form}
        onSubmit={handleSubmit(onSubmit)}
      >
         <div className={styles.logo}>
          <img
            src={logo}
            alt="logo"
          />
        </div>
        <div className={styles.field}>
          <InputEmail
            name="email"
            control={control}
            className="primary"
            required
            label="Email"
            placeholder="Email..."
          />
          {errors.email && <ErrorField message={errors.email.message} />}
        </div>
        <div className={styles.field}>
          <InputPassword
            name="password"
            control={control}
            required
            requiredMessage="Please insert your password"
            className="primary"
            label="Password"
            placeholder="Password..."
          />
          {errors.password && <ErrorField message={errors.password.message} />}
        </div>
        {(!errors.email && !errors.password) && error
          && (
            <p className={styles.error}>
              {error}
            </p>
          )}
        <button
          type="submit"
          className={`${styles.btn} ${styles.primary}`}>
          Login
        </button>
      </form>
    </div>
  );
}
