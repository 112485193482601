import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import {
  Button, ErrorField, StyledSelect,
} from '../../lib/HooksFormFields';
import styles from './ExportModale.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { ExportFiltered } from '../../types/companies.types';
import Loader from '../Loader';
import { EXPORT_MODALE } from '../../actions/actions';
import requiredRule from '../../constants';
import { IOption } from '../../lib/HooksFormFields/StyledSelect/style';
import { exportCompaniesFilteredAction } from '../../actions/companies';

export default function ExportModale({ close }: { close: () => void }) {
  const dispatch = useAppDispatch();
  const { modales, error, lists } = useAppSelector((state: any) => state.companies);
  const { exportModale } = modales;

  const {
    handleSubmit,
    control,
    // watch,
    formState: { errors },
  } = useForm<ExportFiltered>({ mode: 'all' });

  async function onSubmit(form : any) {
    const companies = form.companies.map((v : IOption) => v.value);
    const years = form.years.map((v : IOption) => v.value);
    const indicators = form.indicators.map((v : IOption) => v.value);
    const template = form.template?.value;
    const refs = form.refs?.map((v : IOption) => v.value);
    const period = form.period?.value;
    const data = {
      companies, years, indicators, template, refs, period,
    };
    await exportCompaniesFilteredAction(dispatch, data, exportModale);
  }

  return (
    <div className={styles.container}>
      {exportModale?.fileState !== 'loading' || (exportModale?.fileState === 'loading' && error?.data)
        ? <button
          type="button"
          className={styles.close}
          onClick={() => close()}
        >
          <IoMdClose />
        </button>
        : null
      }
      {exportModale?.fileState === 'upload' && lists?.export
        && <div className={styles.form}>
        <h3>{exportModale?.name}</h3>

        <div className={styles.field}>
          <StyledSelect
            control={control}
            options={lists.export.companies}
            rules={requiredRule}
            isMulti
            placeholder="Choose manufacturers"
            allowSelectAll={true}
            name='companies'
            label='Manufacturer'
          />
          <ErrorField message={errors?.companies?.message}/>
        </div>
        <div className={styles.field}>
          <StyledSelect
            control={control}
            options={lists.export.years}
            rules={requiredRule}
            isMulti
            placeholder="Choose years"
            allowSelectAll={true}
            name='years'
            label='Années'
          />
          <ErrorField message={errors?.years?.message}/>
        </div>
        <div className={styles.field}>
          <StyledSelect
            control={control}
            options={lists.export.indicators}
            rules={requiredRule}
            isMulti
            placeholder="Choose indicators"
            allowSelectAll={true}
            name='indicators'
            label='Indicators'
          />
          <ErrorField message={errors?.indicators?.message}/>
        </div>

        <div className={styles.field}>
          <StyledSelect
            control={control}
            options={lists.export.refs}
            rules={requiredRule}
            isMulti
            placeholder="Choose ref"
            name='refs'
            label='Refs'
          />
          <ErrorField message={errors?.refs?.message}/>
        </div>

        <div className={styles.field}>
          <StyledSelect
            control={control}
            options={lists.export.periods}
            rules={requiredRule}
            placeholder="Choose a period"
            name='period'
            label='Period'
          />
          <ErrorField message={errors?.period?.message}/>
        </div>

        <div className={styles.field}>
          <StyledSelect
            control={control}
            options={lists.export.template}
            rules={requiredRule}
            placeholder="Choose template"
            name='template'
            label='Template'
          />
          <ErrorField message={errors?.template?.message}/>
        </div>

        <Button
          type="button"
          handleClick={handleSubmit(onSubmit)}
          className={'primary'}
          isLoading={false}
        >
          Export
        </Button>
      </div>
      }
      {exportModale?.fileState === 'loading' && !error?.data
        && <div
          className={styles.form}
          >
          <h3>File export is in process</h3>
          <p>It may take several minutes</p>
          <div className={styles.loader}>
            <Loader />
          </div>
        </div>
      }
      {exportModale?.fileState === 'loading' && error?.data
        && <div
          className={styles.form}
          >
          <h3>An error append during the process</h3>
            <div className={styles.error}>
            <div className={styles.icon}>
              <MdOutlineErrorOutline />
            </div>
            <p>{error?.data?.error || 'the process of updating failed'}</p>

            <button
              type="button"
              className={styles.primary}
              onClick={() => dispatch({
                type: EXPORT_MODALE,
                payload: { ...exportModale, fileState: 'upload' },
              })}
            >
              Try again
            </button>
          </div>
        </div>
      }
      {exportModale?.fileState === 'success'
        && <div
          className={styles.form}
          >
          <h3>Your file is exported</h3>
          <div className={styles.success}>
            <div className={styles.icon}>
              <HiCheck />
            </div>
            <p>{exportModale?.name} done</p>
          </div>
        </div>
      }
    </div>
  );
}
