import { Action } from '../types';
import {
  DELETE_USER,
  GET_USERS, POST_USER, PUT_USER,
} from '../actions/actions';
import { UsersState } from '../types/users.types';

const initialState: UsersState = {
  users: null,
  isLoading: false,
  error: null,
};

const usersReducer = (
  state: UsersState = initialState,
  action: Action = {},
): UsersState => {
  let updatedState = { ...state };
  const list = updatedState.users || [];
  const index = list.findIndex((u) => u._id === action?.payload?._id);
  switch (action.type) {
    case GET_USERS:
      updatedState = {
        ...state,
        users: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case POST_USER:
      updatedState = {
        ...state,
        users: [...list, action.payload],
        isLoading: false,
        error: null,
      };
      break;
    case PUT_USER:
      if (index !== -1) {
        list[index] = { ...action.payload };
      }
      updatedState = {
        ...state,
        users: list,
        isLoading: false,
        error: null,
      };
      break;
    case DELETE_USER:
      updatedState = {
        ...state,
        users: [...list.filter((u) => u._id !== action.payload)],
        isLoading: false,
        error: null,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default usersReducer;
