import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { reducers } from './reducers';
import AppRoutes from './Routes';
import Mobile from './pages/Mobile/Mobile';
import useWindowSize from './hooks/useWindowSize';

function App() {
  const { width, height } = useWindowSize();
  return (
    <Provider store={reducers}>
      <div className="App">
        {width >= 924 && height >= 400
          ? (
            <Router>
              <AppRoutes />
            </Router>
          )
          : <Mobile />}
      </div>
    </Provider>
  );
}

export default App;
