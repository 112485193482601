// AUTH ACTIONS
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_TOAST = 'SET_TOAST';
export const SET_CONFIRM = 'SET_CONFIRM';

// USERS ACTIONS
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const POST_USER = 'POST_USER';
export const PUT_USER = 'PUT_USER';
export const DELETE_USER = 'DELETE_USER';
export const USER_ERROR = 'USER_ERROR';
export const USER_LOADING = 'USER_LOADING';

// COMPANIES ACTIONS
export const GET_COMPANIES = 'GET_COMPANIES';
export const PUT_COMPANY = 'PUT_COMPANY';
export const EXPORT_COMPANY = 'EXPORT_COMPANY';
export const UPLOAD_COMPANY_MODALE = 'UPLOAD_COMPANY_MODALE';
export const COMPANY_ERROR = 'COMPANY_ERROR';
export const COMPANY_LOADING = 'COMPANY_LOADING';
export const COMPANY_MESSAGE = 'COMPANY_MESSAGE';
export const UPLOAD_FILE_MODALE = 'UPLOAD_FILE_MODALE';
export const EXPORT_MODALE = 'EXPORT_MODALE';
export const GET_EXPORT_LISTS = 'GET_EXPORT_LISTS';

export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS';
