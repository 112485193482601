import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { FiPlusSquare } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { Button, ErrorField, InputEmail } from '../../lib/HooksFormFields';
import { IUserForm } from '../../types/users.types';
import img from './add-user.svg';
import style from './AddUser.module.scss';
import { postUserAction } from '../../actions/user';
import { useAppDispatch } from '../../hooks/useRedux';

export default function AddUser({ close }: { close: () => void }) {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IUserForm>();

  async function onSubmit(form : IUserForm) {
    await postUserAction(dispatch, form);
    close();
  }

  return (
    <div className={style.container}>
      <button
        type="button"
        className={style.close}
        onClick={() => close()}
      >
        <IoMdClose />
      </button>
      <div className={style.img}>
        <img src={img} alt="add-img" />
      </div>
      <div
        className={style.form}
      >
        <h3>New user</h3>
        <InputEmail
          control={control}
          name='email'
          label='Email'
          className={'primary'}
          required
        />
        <ErrorField message={errors?.email?.message}/>
        <Button
          type="button"
          handleClick={handleSubmit(onSubmit)}
          className={'primary'}
          isLoading={false}
        >
          <>
            <FiPlusSquare /> Add user
          </>
        </Button>
      </div>
    </div>
  );
}
