import React, { useCallback, useEffect } from 'react';
import { getCompaniesAction, getExportListsAction } from '../../../../actions/companies';
import CompaniesList from '../../../../components/CompaniesList/CompaniesList';
import { useAppDispatch } from '../../../../hooks/useRedux';
import styles from './Companies.module.scss';

export default function Companies() {
  const dispatch = useAppDispatch();
  const getCompanies = useCallback(() => {
    getCompaniesAction(dispatch);
    getExportListsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getCompanies();
  }, []);
  return (
    <div className={styles.container}>
      <CompaniesList />
    </div>
  );
}
