import React, { useEffect, useRef, useState } from 'react';
import { MdDeleteForever } from 'react-icons/md';
import { FiPlusSquare } from 'react-icons/fi';
import { deleteUserAction, putUserAction } from '../../actions/user';
// import { MdAdminPanelSettings } from 'react-icons/md';
// import { RiVipCrown2Fill } from 'react-icons/ri';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import { Button } from '../../lib/HooksFormFields';
import ModalStandalone, { IModalHandle } from '../../lib/ModalStandalone';
import { IUser } from '../../types/auth.types';
import AddUser from '../AddUser/AddUser';
import Search from '../Search/Search';
import SelectRole from '../SelectRole/SelectRole';
import SortItem, { ISortItem } from '../SortItem/SortItem';

import styles from './UserList.module.scss';
import { SET_CONFIRM } from '../../actions/actions';
import Loader from '../Loader';

const UserRow = (
  {
    user,
    index,
  }
  :
  {
    user: IUser,
    index: number,
  },
) => {
  const dispatch = useAppDispatch();
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <p>{user?.name}</p>
      </div>
      <div className={styles.col}>
        <p>{user.email}</p>
      </div>
      <div className={styles.col}>
        <SelectRole
          role={user.role}
          index={index}
          handleChange={(role) => putUserAction(dispatch, user._id, { ...user, role })}
        />
      </div>
      <button
        type="button"
        className={styles.delete}
        onClick={() => dispatch({
          type: SET_CONFIRM,
          payload: {
            message: 'Please confirm deletion',
            action: () => deleteUserAction(dispatch, user._id),
          },
        })}
      >
        <MdDeleteForever />
      </button>
    </div>
  );
};

export default function UserList() {
  const modaleRef = useRef<IModalHandle>(null);
  const { users } = useAppSelector((state: any) => state.users);

  const sortItems = [
    { value: 'name', label: 'Name' },
    { value: 'email', label: 'Email adress' },
    { value: 'role', label: 'Role' },
  ];
  const [activeSort, setActiveSort] = useState<ISortItem>({ ...sortItems[1], isAsc: true });
  const [filteredList, setFilteredList] = useState<IUser[]>([]);
  const [search, setSearch] = useState('');

  function handleSort(usersList: IUser[]) {
    let result = usersList
      .map((user : IUser) => {
        const emailProfile = user.email.split('.');
        const name = user?.profile?.firstName
          ? `${user?.profile?.firstName} ${user?.profile?.lastName}`
          : `${emailProfile[0]} ${emailProfile[1].split('@')[0]}`;
        return { ...user, name };
      })
      .filter((u : IUser) => {
        if (!search) {
          return u;
        }
        if (
          u.name
          && u.name.toLowerCase().includes(search.toLowerCase())
        ) {
          return u;
        }
        return null;
      });
    result = result.sort((a, b) => {
      const aName = a.name.split(' ')[1];
      const bName = b.name.split(' ')[1];
      switch (activeSort?.value) {
        case 'email':
          return b.email.localeCompare(a.email);
        case 'role':
          return b.role.localeCompare(a.role);
        case 'name':
          return aName.localeCompare(bName);
        default:
          return aName.localeCompare(bName);
      }
    });
    setFilteredList(!activeSort?.isAsc ? result : result.reverse());
  }

  useEffect(() => {
    if (users) {
      handleSort(users);
    }
  }, [users, search, activeSort]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Search
          search={search}
          setSearch={setSearch}
        />
        <Button
          type="button"
          handleClick={() => modaleRef?.current && modaleRef.current.open()}
          className={'primary'}
          isLoading={false}
        >
          <>
            <FiPlusSquare /> Add user
          </>
        </Button>
      </div>
      <div className={styles.row}>
        {sortItems.map((item) => <SortItem
          key={item.value}
          item={item}
          isAsc={!!(item.label === activeSort.label && activeSort.isAsc) }
          styles={styles}
          setActiveSort={setActiveSort}
        />)}
      </div>
      {users
        ? <div className={styles.list}>
        {filteredList.map((u, i) => <UserRow
          key={u._id}
          user={u}
          index={i}
        />)}
      </div>
        : <div className={styles.loader}>
          <Loader/>
      </div>
      }
      <ModalStandalone ref={modaleRef}>
        <AddUser
          close={() => modaleRef?.current && modaleRef.current.close()}
        />
      </ModalStandalone>
    </div>
  );
}
