import { Action } from '../types';
import {
  COMPANY_ERROR,
  EXPORT_COMPANY,
  EXPORT_MODALE,
  GET_COMPANIES,
  GET_EXPORT_LISTS,
  PUT_COMPANY,
  UPLOAD_COMPANY_MODALE,
  UPLOAD_FILE_MODALE,
} from '../actions/actions';
import lists from '../constants/lists';
import { CompaniesState, ICompany } from '../types/companies.types';

const initialState: CompaniesState = {
  companies: null,
  isLoading: false,
  error: null,
  modales: {
    companyModale: null,
    fileModale: null,
    exportModale: null,
  },
  lists,
};

const companiesReducer = (
  state: CompaniesState = initialState,
  action: Action = {},
): CompaniesState => {
  let updatedState = { ...state };
  const list = updatedState.companies ? [...updatedState.companies] : [];
  const index = list.findIndex((u: ICompany) => u._id === action?.payload?._id);
  switch (action.type) {
    case UPLOAD_COMPANY_MODALE:
      updatedState = {
        ...state,
        modales: {
          ...state.modales,
          companyModale: action.payload,
        },
        error: null,
      };
      break;
    case EXPORT_MODALE:
      updatedState = {
        ...state,
        modales: {
          ...state.modales,
          exportModale: action.payload,
        },
        error: null,
      };
      break;
    case UPLOAD_FILE_MODALE:
      updatedState = {
        ...state,
        modales: {
          ...state.modales,
          fileModale: action.payload,
        },
        error: null,
      };
      break;
    case GET_COMPANIES:
      updatedState = {
        ...state,
        companies: action.payload,
        isLoading: false,
        error: null,
      };
      break;
    case EXPORT_COMPANY:
      if (index !== -1) {
        list[index] = { ...list[index], isLoading: !list[index].isLoading };
      }
      updatedState = {
        ...state,
        companies: list,
        isLoading: false,
        error: null,
      };
      break;
    case PUT_COMPANY:
      if (index !== -1) {
        list[index] = { ...action.payload };
      }
      updatedState = {
        ...state,
        companies: list,
        isLoading: false,
        error: null,
      };
      break;
    case GET_EXPORT_LISTS:
      updatedState = {
        ...state,
        lists: { ...state.lists, export: action.payload },
      };
      break;
    case COMPANY_ERROR:
      updatedState = {
        ...state,
        error: action.payload,
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default companiesReducer;
