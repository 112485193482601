import React from 'react';
import logo from '../../assets/images/logo.png';
import styles from './Mobile.module.scss';

export default function Mobile() {
  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <img
          src={logo}
          alt="logo"
        />
      </div>
      <h4>
        The application is not available
        <br/>
        on tablet or mobile
      </h4>
    </div>
  );
}
