import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';

import { useAppSelector, useAppDispatch } from './hooks/useRedux';
import { AUTH_GET_PROFILE, AUTH_LOGOUT } from './actions/actions';

import Loader from './components/Loader';
import Toaster from './components/Toaster';
// import Connection from './pages/Auth/Connection/Connection';
import Login from './pages/Auth/Login/Login';
import Finance from './pages/Finance/Finance';
import Page404 from './pages/Page404/Page404';
import Users from './pages/Finance/pages/Users/Users';
import Companies from './pages/Finance/pages/Companies/Companies';
import CustomConfirm from './lib/CustomConfirm/CustomConfirm';

function Redirect() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { userAuth } = useAppSelector((state: any) => state.auth);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = jwtDecode(token);
      dispatch({
        type: AUTH_GET_PROFILE,
        payload: user,
      });
      navigate('/finance/manufacturers');
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (userAuth?.role) {
      navigate('/finance/manufacturers');
    }
  }, [userAuth]);

  return <Loader />;
}

function AppRoutes() {
  const { error, userAuth } = useAppSelector((state: any) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = jwtDecode(token);
      dispatch({
        type: AUTH_GET_PROFILE,
        payload: user,
      });
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (error === "Vous n'êtes pas autoriser à accéder à ce contenu") {
      dispatch({
        type: AUTH_LOGOUT,
      });
      navigate('/login');
    }
  }, [error]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Redirect />} />
        {/* <Route path="/connection" element={<Connection />} /> */}
        <Route path="/login" element={<Login /> } />
        {userAuth?.role && (
          <Route path="/finance" element={<Finance />}>
            <Route path="/finance/users" element={<Users />} />
            <Route path="/finance/manufacturers" element={<Companies />} />
          </Route>
        )}
        <Route path="*" element={<Page404 />} />
      </Routes>
      <Toaster />
      <CustomConfirm />
    </>
  );
}

export default AppRoutes;
