import React from 'react';
import { IoMdClose } from 'react-icons/io';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { HiCheck } from 'react-icons/hi';
import { useForm } from 'react-hook-form';
import {
  Button, ErrorField, InputFile,
} from '../../lib/HooksFormFields';
import img from './file.svg';
import styles from './FileModale.module.scss';
import { useAppDispatch, useAppSelector } from '../../hooks/useRedux';
import requiredRule from '../../constants';
import { IFileForm } from '../../types/companies.types';
import { uploadExchangeAction, uploadFormulaAction } from '../../actions/companies';
import Loader from '../Loader';
import { UPLOAD_FILE_MODALE } from '../../actions/actions';

export default function FileModale({ close }: { close: () => void }) {
  const dispatch = useAppDispatch();
  const { modales, error } = useAppSelector((state: any) => state.companies);
  const { fileModale } = modales;

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IFileForm>({ mode: 'all' });

  console.log(error);

  async function onSubmit(form : IFileForm) {
    const formData = new FormData();
    formData.append('file', form.file[0]);
    const options = {
      _id: 'formula',
      selectedYears: 'all',
    };
    if (fileModale?.name === 'Formula') {
      formData.append('options', JSON.stringify(options));
      return uploadFormulaAction(dispatch, formData, { ...fileModale });
    }
    return uploadExchangeAction(dispatch, formData, { ...fileModale });
  }

  return (
    <div className={styles.container}>
      {fileModale?.fileState !== 'loading' || (fileModale?.fileState === 'loading' && error?.data)
        ? <button
          type="button"
          className={styles.close}
          onClick={() => close()}
        >
          <IoMdClose />
        </button>
        : null
      }
      <div className={styles.img}>
        <img src={img} alt="add-img" />
      </div>

      {fileModale?.fileState === 'upload'
        && <div
          className={styles.form}
        >
        <h3>Upload your <span className={styles.name}>{fileModale?.name}</span> file</h3>
        <InputFile
          control={control}
          name='file'
          id='input-file'
          btn={watch('file')?.length > 0 ? watch('file')[0].name : 'Choose a file'}
          rules={requiredRule}
        />
        <ErrorField message={errors?.file?.message}/>
        <Button
          type="button"
          handleClick={handleSubmit(onSubmit)}
          className={'primary'}
          isLoading={false}
        >
          <>
            <HiCheck /> Validate
          </>
        </Button>
      </div>
      }
      {fileModale?.fileState === 'loading' && !error?.data
        && <div
          className={styles.form}
          >
          <h3>
            <span className={styles.name}>{fileModale?.name}</span>
            {' '}
            file is in the process of updating</h3>
          <p>it may take several minutes</p>
          <div className={styles.loader}>
            <Loader />
          </div>
        </div>
      }
      {fileModale?.fileState === 'loading' && error?.data
        && <div
          className={styles.form}
          >
          <h3>An error append during the process</h3>
            <div className={styles.error}>
            <div className={styles.icon}>
              <MdOutlineErrorOutline />
            </div>
            <p>{error?.data?.error || 'the process of updating failed'}</p>

            <button
              type="button"
              className={styles.primary}
              onClick={() => dispatch({
                type: UPLOAD_FILE_MODALE,
                payload: { ...fileModale, fileState: 'upload' },
              })}
            >
              Change file
            </button>
          </div>
        </div>
      }
      {fileModale?.fileState === 'success'
        && <div
          className={styles.form}
          >
          <h3>Your <span className={styles.name}>{fileModale?.name}</span> file is is updated</h3>
          <div className={styles.success}>
            <div className={styles.icon}>
              <HiCheck />
            </div>
            <p>{fileModale?.name} replaced</p>
          </div>
        </div>
      }
    </div>
  );
}
