import React from 'react';
import { BiSearch } from 'react-icons/bi';
import styles from './Search.module.scss';

export default function Search(
  {
    search,
    setSearch,
  }:
  {
    search: string,
    setSearch: (string: string) => void
  },
) {
  return (
    <div className={styles.container}>
      <BiSearch />
      <input
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder='Your search'
      />
    </div>
  );
}
